@import '../../index.css';

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
   width: 100%;
   height: 100vh;
   background: url("../img/avatar.png") bottom center no-repeat;
   background-size: 50vh;
   position: relative;
}

@media (min-width: 992px) {
   #hero {
      background: url("../img/avatar2.png") bottom no-repeat;
      background-size: 100vw;
      background-position-x: right;
      left: 10%;
      width: calc(90% - 25px);
   }
}

/* #hero:before {
   content: "";
   background: rgba(255, 255, 255, 0.8);
   position: absolute;
   bottom: 0;
   top: 0;
   left: 0;
   right: 0;
 } */

#hero h1 {
   margin: 0;
   font-size: 64px;
   font-weight: 700;
   line-height: 56px;
   color: #515151;
}

#hero p {
   color: #515151;
   margin: 15px 0 0 0;
   font-size: 26px;
   font-family: "Poppins", sans-serif;
}

#hero p span {
   color: #ffa123;
   letter-spacing: 1px;
}

#hero .social-links {
   margin-top: 30px;
}

#hero .social-links a {
   font-size: 24px;
   display: inline-block;
   color: #515151;
   line-height: 1;
   margin-right: 20px;
   transition: 0.3s;
}

#hero .social-links a:hover {
   color: #ffa123;
}

@media (max-width: 992px) {
   #hero {
      text-align: center;
      justify-content: start !important;
      padding-top: calc(25vh - 10px - 5vw);
   }

   #hero h1 {
      font-size: 32px;
      line-height: 36px;
   }

   #hero p {
      margin-top: 10px;
      font-size: 20px;
      line-height: 24px;
   }

   #hero .social-links {
      margin-top: calc(8vh - 20px);
   }
}