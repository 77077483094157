body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F9F9F9 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  font-family: 'Helvetica Neue', sans-serif;
  color: #272829;
}

a {
  color: #ffa123;
  text-decoration: none !important; 
}

a:hover {
  color: #ffa123;
  text-decoration: none !important;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Helvetica Neue', sans-serif;
}


 /*--------------------------------------------------------------
 # Sections General
 --------------------------------------------------------------*/
 section {
  padding: 60px 0;
  overflow: hidden;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: #45505b;
}

.section-title h2::before {
  content: '';
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section-title h2::after {
  content: '';
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #ffa123;
  bottom: 0;
  left: calc(50% - 20px);
}

.section-title p {
  margin-bottom: 0;
}

::selection {
  color: #313131;
  background: #ffc16f;
}